/* Mobile view adjustments */
@media (max-width: 600px) {
    .pop-title {
      font-size: 18px;
      /* Reduce the title font size */
    }
  
    .pop-logo img {
      height: 40px;
      /* Adjust the logo size */
    }
  
    #topDescription,
    #bottomDescription {
      font-size: 12px;
      /* Reduce description text size */
      line-height: 1.4;
      /* Make it more compact */
    }
  
    .btn {
      width: 100%;
      /* Ensure button is full width on mobile */
      padding: 10px 15px;
      /* Adjust padding */
    }
  
    .powered-by-footer {
      flex-direction: column;
      /* Stack the footer elements */
      text-align: center;
      margin-top: 10px;
    }
  
    .powered-by-footer small {
      font-size: 10px;
      /* Reduce the size of footer text */
    }
  
    /* Accordion adjustments */
    .accordion-button {
      font-size: 14px;
      /* Adjust accordion button text size */
    }
  
    .pro-box h4 {
      font-size: 12px;
      /* Reduce the text size inside the data sections */
    }
  
    /* Remove excess spacing around elements */
    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  
    .col-md-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    /* Remove padding and margin from the bottom */
    .scrollable-modal {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  
    /* Adjust the footer to remove excess space */
    .powered-by-footer {
      padding: 10px 0;
      /* Reduce padding */
      margin-bottom: 0;
      /* Remove unnecessary margin */
    }
  
    /* Ensure that no extra space is added at the bottom */
    #card01 {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      height: auto !important;
      /* Ensure height is auto */
    }
  
  }