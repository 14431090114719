body {
    font-family: 'JioType';
    font-weight: 400;
    font-size: 16px;
    // background: #f1f0f0;

    &.fix-header{
        .scrollUp {
            transform: none;
        }
    }

    &.manage{
        main{
            margin-top: 124px;
        }
    }
}

#style-3::-webkit-scrollbar-track{
    background-color: transparent;
}

#style-3::-webkit-scrollbar{
    margin: 10px 0;
    width: 6px;
    background-color: #FFF;
}

#style-3::-webkit-scrollbar-thumb{
    background-color: #E5E5E5;
}

strong {
    font-weight: 700;
}

.text-primary {
    color: #00337F !important;
}

.text-danger{
    color: #E30513 !important;
}

#pre-load {
    background-color: #fff;
    height: 100%;
    width: 100%;
    position: fixed;
    margin-top: 0px;
    top: 0px;
    //z-index: 99999;

    .loader-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        border: 3px solid #ebebec;
        border-radius: 50%;

        &::before {
            position: absolute;
            content: "";
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 100px;
            border-top: 3px solid #517599;
            border-radius: 50%;
            animation: loaderspin 1.8s infinite ease-in-out;
            -webkit-animation: loaderspin 1.8s infinite ease-in-out;
        }

        .loader-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            text-align: center;

            img {
                animation: loaderpulse alternate 900ms infinite;
                width: 40px;
            }
        }
    }
}

@keyframes loaderspin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@-webkit-keyframes loaderspin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes loaderpulse {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}

@keyframes loaderspin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@-webkit-keyframes loaderspin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes loaderpulse {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}

.status{
    padding: 3px 12px;
    display: inline-block;
    color: #FFF;
    font-size: 12px;
    min-width: 70px;
    text-align: center;
    background: #000;
    border: 1px solid #000;
    border-radius: 25px;

    &.active{
        color: #25AB21;
        background: #E7FFE7;
        border-color: #25AB21;
    }
}

.custom-cards{
    border: none;
    border-radius: 20px;
    -webkit-box-shadow: 0px 4px 16px -6px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 4px 16px -6px rgba(0,0,0,0.3);
    box-shadow: 0px 4px 16px -6px rgba(0,0,0,0.3);

    &.dark{
        background: #F5F5F5;
    }

    &.cards-border{
        border: 1px solid #E5E5E5;
    }

    &.no-shadow{
        box-shadow: none !important;
    }

    .card-body{
        h4{
            color: #141414;
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
        }

        .chat-legend{
            ul{
                margin: 0;
                padding: 0;
                list-style: none;

                li{
                    display: inline-block;
                    color: #141414;
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 26px;
                    width: 25%;
                    text-align: center;
                    span{
                        display: block;
                        color: #999;
                        font-size: 10px;
                        line-height: 20px;
                    }

                    &.active{
                        span{
                            color: #25AB21;
                        }
                    }

                    &.rejected{
                        span{
                            color: #F80B0B;
                        }
                    }

                    &.revoked{
                        span{
                            color: #1542C6;
                        }
                    }
                }
            }
        }
    }
}

.check-list{
    ul{
        margin: 0;
        padding: 0;
        list-style: none;

        li{
            display: block;
            color: #333;
            font-size: 14px;
            font-weight: 500;
            line-height: 25px;
        }
    }
}

.video-popup{
    border-radius: 0;
    .modal-dialog{
        border-radius: 0;
        .modal-content{
            background: transparent;
            border: none;
            border-radius: 0;
            .modal-header{
                padding: 10px 0;
                background: transparent;
                border: none;
                border-radius: 0;
                .btn-close{
                    padding: 0;
                    width: 48px;
                    height: 48px;
                    background: transparent url(../../../public/Img/close.png) no-repeat;
                }
            }
            .modal-body{
                padding: 0;
            }
        }
    }
}

.offcanvas{
    &.offcanvas-start{
        width: 322px;

        .offcanvas-header{
            padding-left: 50px;
            padding-right: 50px;
            border-bottom: 1px solid #E8E8E8;
        }

        .offcanvas-body{
            padding-left: 50px;
            padding-right: 50px;
            .sidebar-nav{
                margin: 0;
                padding: 0 !important;
                .MuiButtonBase-root{
                    padding: 15px 0;
                    border-bottom: 1px solid #E5E5E5;
                    
                    .MuiListItemIcon-root{
                        min-width: 40px;
                        svg{
                            fill: #000;
                            fill-opacity: 0.56;
                        }
                    }
                    .MuiTypography-root{
                        color: #333333;
                        font-size: 14px;
                        line-height: 14px;
                        font-family: 'JioType';
                        font-weight: 500;
                    }
                    &.active{
                        color: #000;
                        .MuiListItemIcon-root{
                            svg{
                                fill: #000;
                                fill-opacity: 1;
                            }
                        }
                    }
                    &:hover{
                        background: transparent;
                        .MuiTypography-root{
                            color: #DC0000;
                        }
                        .MuiListItemIcon-root{
                            
                            svg{
                                fill: #DC0000;
                                fill-opacity: 1;
                            }
                        }
                    }

                    &:last-child{
                        border: none;
                    }
                }
            }
        }
    }
}

.f-14{
    font-size: 14px;
}

.btn-customized{
    padding: 12px;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    background: #FFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;

    &:hover,
    &:focus,
    &:active,
    &.active{
        background: #FFF;
        border: 1px solid #D9D9D9;
    }
}

.btn-customized-link{
    padding: 8px 0;
    color: #999;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    background: transparent;
    border: none;

    &:hover,
    &:focus,
    &.active{
        color: #0F3CC9;
    }
}

.btn-primary {
    font-family: "JioType";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-left: 40px;
    padding-right: 40px;
    background: #3535F3;
    border-color: #3535F3;
    border-radius: 25px;

    svg{
        margin-right: 10px;
        path{
            fill: #FFF;
        }
    }

    &:focus,
    &:hover{
        color: #0A2885;
        background: transparent;

        svg{
            path{
                fill: #0A2885;
            }
        }
    }

    &.btn-sm{
        font-size: 12px;
    }
}

.btn-outline-primary{
    padding-left: 40px;
    padding-right: 40px;
    color: #0A2885;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    border-color: #B5B5B5;
    border-radius: 25px;

    svg{
        margin-right: 10px;
    }

    &.drag{
        color: #000093;
    }
    
    &:focus,
    &:hover{
        color: #FFF;
        background-color: #3535F3;
        border-color: #3535F3;

        svg{
            path{
                fill: #FFF;
            }
        }
    }
}

.btn-r25{
    border-radius: 25px;
}

.btn-color{
    padding: 0;
    background: #f1f1f1;
    width: 20px;
    height: 20px;
    border-radius: 4px;    
}

.MuiInputLabel-root{
    color: rgba(0, 0, 0, 0.65) !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.MuiAccordion-root{
    .MuiButtonBase-root{
        .MuiAccordionSummary-content{
            padding: 0 !important;
        }
    }
}

.status-scroll{
    padding-bottom: 10px;
    overflow-x: auto;

    // Style the scrollbar
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: #F5F5F5;
        border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 4px;
        
        &:hover {
            background-color: darken(#ccc, 10%);
        }
    }
}

.pop-footer-link{
    margin: 0;
    padding: 0;
    list-style: none;

    li{
        position: relative;
        padding: 0 10px;
        display: inline-block;

        &::before{
            position: absolute;
            top: 20%;
            right: 0;
            content: "";
            width: 1px;
            height: 60%;
            background: #0A2885;
        }

        &:last-child{
            &::before{
                display: none;
            }
        }

        a{
            color: #0A2885;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            text-decoration: none;
        }
    }
}

main {
    //margin-top: 72px;
    margin-bottom: 67px;

    .content-header{
        padding: 15px 15px 0;

        .page-title{
            position: relative;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 10px;
            color: #020132;
            font-size: 30px;
            font-weight: 900;

            span{
                margin-top: 10px;
                display: block;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    .content{
        padding: 15px;
    }

    .table{
        border: 1px solid #E2E2E2;

        thead{
            background: #0F3CC9;
            tr{
                th{
                    padding: 15px;
                    color: #FFF;
                    font-size: 13px;
                    font-weight: 700;
                    
                    // &:first-child{
                    //     border-top-left-radius: 8px;
                    // }

                    // &:last-child{
                    //     border-top-right-radius: 8px;
                    // }
                }
            }
        }
        tbody{
            tr{
                td{
                    padding: 15px;
                    font-size: 12px;
                    font-weight: 500;
                    box-shadow: none;

                    .status{
                        padding: 1px 12px;
                        display: inline-block;
                        color: #FFF;
                        min-width: 90px;
                        text-align: center;
                        background: #000;
                        border: 1px solid #000;
                        border-radius: 25px;

                        &.active{
                            color: #25AB21;
                            background: #E7FFE7;
                            border-color: #25AB21;
                        }

                        &.expired{
                            color: #AAAAAA;
                            background: #EEEEEE;
                            border-color: #AAAAAA;
                        }

                        &.revoked{
                            color: #EC903A;
                            background: #FFEFDF;
                            border-color: #EC903A;
                        }

                        &.denied{
                            color: #EC5B56;
                            background: #FFDFDE;
                            border-color: #EC5B56;
                        }
                    }

                    .btn-view{
                        padding: 0 6px;
                        color: #0F3CC9;
                        font-size: 12px;
                        background: transparent;
                        border: none;

                        svg{
                            width: 20px;
                        }

                        &:hover,
                        &:focus{
                            color: #666;
                        }
                    }

                    .btn-revoke{
                        padding: 1px 12px;
                        color: #000093;
                        font-size: 12px;
                        background: transparent;
                        border: 1px solid #000093;
                        border-radius: 25px;

                        &:hover,
                        &:focus{
                            color: #FFF;
                            background: #000093;
                        }
                    }
                }
                &:nth-of-type(odd){
                    background-color: #FFF !important;
                }
                &:nth-of-type(even){
                    background-color: #f5f5f5;
                }
            }
        }
    }

    .dt-info{
        font-size: 12px;
        font-weight: 500;
    }

    .dt-paging{
        .pagination{
            margin: 0;
            
            .dt-paging-button{
                a{
                    color: rgba(0, 0, 0, 0.65);
                    border: none;
                    border-radius: 0 !important;

                    svg{
                        path{
                            fill: rgba(0, 0, 0, 0.65);
                        }
                    }
                }

                &.active,
                &:hover,
                &:focus{
                    a{
                        color: #0F3CC9;
                        background: transparent !important;

                        svg{
                            path{
                                fill: #0F3CC9;
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-tabs{
        .nav-item{
            margin-right: 20px;

            &:last-child{
                margin-right: 0;
            }

            button{
                margin: 0;
                padding: 6px 0;
                color: #000;
                font-size: 16px;
                border: none;
                border-radius: 0;

                &.active{
                    position: relative;

                    &::before{
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        content: "";
                        background: #0F3CC9;
                        height: 2px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        //margin-top: 72px;
    }

    
}