.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 72px;
    background: transparent;
    z-index: 99;

    transition: all 0.5s;

    &.scrollUp {
        transform: translateY(-72px);
    }

    .navbar {
        padding-top: 0;
        padding-bottom: 0;
        background: #0F3CC9;
        border-bottom: 1px solid #0F3CC9;
        /*-webkit-box-shadow: 0 10px 10px -10px rgba(0,0,0,.4);
                box-shadow: 0 10px 10px -10px rgba(0,0,0,.4);*/

        //z-index: 9999;

        @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-bottom: 0;
        }

        @include media-breakpoint-down(md){
            .input-group{
                display: none;
            }
        }

        @include media-breakpoint-down(lg){
            .container{
                position: relative;
            }
            .navbar-brand {
                position: absolute;
                left: 76px;
                margin-left: 0;
            }

            .input-group{
                max-width: 200px !important;
            }
        }

        @include media-breakpoint-down(xl) {
            .container{
                position: relative;
            }
            .navbar-brand {
                position: relative;
                left: 36px;
                margin-left: 0;
                padding: 15px 0;
            }

            .input-group{
                left: -40px;
                max-width: 400px;
            }

            .right-logo{
                position: absolute;
                right: 64px;
            }
        }

        .menu-icon-btn{
            background: transparent;
            border: none;
        }

        .input-group{
            .input-group-text {
                background: #E8E8FC;
                border-color: #E8E8FC;
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;
            }
    
            .form-control {
                background: #E8E8FC;
                border-color: #E8E8FC;
                border-radius: 25px;
            }
        }

        .navbar-nav {
            padding: 0 15px;

            .nav-item {
                border-top: 1px solid #e9e9e9;

                &:first-child {
                    border-top: none;
                }

                .nav-link {
                    padding: 15px 30px 15px 0;
                    color: #000;
                    font-family: "JioType";
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 20px;

                    &.dropdown-toggle {
                        &::after {
                            position: absolute;
                            top: 18px;
                            right: 12px;
                        }
                    }
                }

                .dropdown-menu {
                    margin: 0;
                    padding: 0;
                    border: none;
                    border-radius: 0;

                    .nav-item {
                        border-top: 1px solid #e9e9e9;

                        a {
                            position: relative;
                            padding: 10px 15px 10px 30px;
                            color: #000;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;

                            &::before {
                                position: absolute;
                                top: 20px;
                                left: 15px;
                                content: "";
                                width: 8px;
                                height: 1px;
                                background: rgba(0, 0, 0, 0.65);
                            }
                        }
                    }
                }

                @include media-breakpoint-up(xl) {
                    border: none;

                    .nav-link {
                        padding: 26px 26px 25px;
                        color: $white;
                        font-weight: 700;

                        &.dropdown-toggle {
                            &::after {
                                display: none;
                            }
                        }
                    }

                    .dropdown-menu {
                        position: fixed;
                        top: 72px;
                        left: 0;
                        right: 0;
                        //display: flex !important;
                        margin: 0;
                        padding: 0;
                        border: none;
                        border-radius: 0;
                        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

                        // -webkit-animation: fadeInDown 400ms;
                        // animation: fadeInDown 400ms;

                        .sub-menu{
                            background: #000093;

                            .nav-item {
                                display: inline-block;
                                border: none;

                                a {
                                    margin-right: 20px;
                                    padding: 13px 0;
                                    color: #FFF;
                                    font-size: 16px;
                                    font-weight: 700;
                                    line-height: 24px;
    
                                    &::before {
                                        display: none;
                                    }

                                    &.active{
                                        border-bottom: 2px solid #FFF;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(xl) {
            .navbar-collapse {
                position: fixed;
                top: 72px;
                left: -250px;
                bottom: 0;
                display: block;
                width: 250px;
                height: calc(100vh - 72px);
                background: #FFF;
                overflow-y: auto;
                z-index: 999;

                transition: all ease-in-out .5s;

                &::before {
                    position: fixed;
                    top: 72px;
                    left: 0;
                    right: 100%;
                    bottom: 0;
                    content: "";
                    background: rgba(0, 0, 0, .65);
                    z-index: -1;

                    transition: all ease-in-out .5s;
                }

                &.show {
                    left: 0;

                    transition: all ease-in-out .5s;

                    &::before {
                        left: 250px;
                        right: 0;

                        transition: all ease-in-out .5s;
                    }
                }
            }
        }

        .profile {
            button {
                margin-left: 0;
                padding: 16px 0 15px;
                background: transparent;
                border: none;

                &::after{
                    display: none;
                }

                &.pro-setting{
                    font-size: 18px;
                }
            }

            .dropdown-menu {
                position: absolute;
                top: 65px;
                margin: 0;
                padding: 10px 25px;
                border: none;
                border-radius: 20px;
                box-shadow: 0px 8px 16px 0px #B5B5B5;

                li {
                    border-top: 1px solid #e9e9e9;

                    &:first-child{
                        border: none;
                    }

                    a {
                        position: relative;
                        padding: 15px 0;
                        display: flex;
                        color: #333;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 20px;

                        i,
                        svg {
                            margin-right: 10px;
                            color: #0F3CC9;
                            font-size: 22px;
                        }

                        &:hover,
                        &:focus,
                        &:active,
                        &.active{
                            color: #0F3CC9;
                            background: transparent;
                        }
                    }
                }

                &.ac-menu{
                    min-width: 250px;
                    max-height: 400px;
                    overflow-y: auto;

                    .drop-label{
                        margin: 5px 0;
                        color: #000;
                        font-size: 20px;
                        font-weight: 900;
                        line-height: 24px;
                    }

                    .MuiPaper-root{
                        margin: 0;
                        border-radius: 0;
                        box-shadow: none;

                        .MuiButtonBase-root{
                            padding: 0;
                            min-height: 40px !important;

                            .Mui-expanded{
                                min-height: 40px !important;
                                height: 40px !important;
    
                                &::before{
                                    opacity: 1 !important;
                                }
                            }

                            .MuiAccordionSummary-content{
                                margin: 0;

                                .MuiFormControlLabel-root{
                                    margin: 0;

                                    .MuiCheckbox-root{
                                        .MuiSvgIcon-root{
                                            margin-right: 10px;
                                            width: 18px;
                                            height: 18px;
                                        }
                                    }

                                    .MuiTypography-root{
                                        color: rgba(0, 0, 0, 0.65);
                                        font-family: "JioType";
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 20px;
                                    }
                                }
                            }
                        }

                        .MuiCollapse-root{
                            .MuiCollapse-wrapper{
                                .MuiFormControlLabel-root{
                                    margin: 0;

                                    &:last-child{
                                        margin: 0;
                                    }

                                    .MuiCheckbox-root{
                                        .MuiSvgIcon-root{
                                            margin-right: 10px;
                                            width: 18px;
                                            height: 18px;
                                        }
                                    }

                                    .MuiTypography-root{
                                        color: rgba(0, 0, 0, 0.65);
                                        font-family: "JioType";
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 20px;
                                    }
                                }
                            }
                        }
                    }

                    // .accordion{
                    //     border: none;
                    //     border-radius: 0;
    
                    //     .accordion-item{
                    //         border: none;
                    //         border-bottom: 1px solid #ccc;
                    //         border-radius: 0;

                    //         &:last-child{
                    //             border-bottom: none;
                    //         }
    
                    //         .accordion-header{
                    //             .accordion-button{
                    //                 padding: 0;
                    //                 color: rgba(0, 0, 0, 0.65);
                    //                 font-size: 14px;
                    //                 font-weight: 500;
                    //                 line-height: 20px;
                    //                 border-radius: 0;
                    //                 box-shadow: none;
    
                    //                 .MuiFormControlLabel-root{
                    //                     .MuiCheckbox-root{
                    //                         .MuiSvgIcon-root{
                    //                             width: 18px;
                    //                             height: 18px;
                    //                         }
                    //                     }
                    //                     .MuiTypography-root{
                    //                         color: rgba(0, 0, 0, 0.65);
                    //                         font-size: 14px;
                    //                         font-family: "JioType";
                    //                         font-weight: 500 !important;
                    //                         line-height: 20px;
                    //                     }
                    //                 }
                    //             }
                    //         }
    
                    //         .accordion-body{
                    //             padding: 0 10px;

                    //             .MuiFormControlLabel-root{
                    //                 .MuiCheckbox-root{
                    //                     .MuiSvgIcon-root{
                    //                         width: 18px;
                    //                         height: 18px;
                    //                     }
                    //                 }
                    //                 .MuiTypography-root{
                    //                     color: rgba(0, 0, 0, 0.65);
                    //                     font-size: 14px;
                    //                     font-family: "JioType";
                    //                     font-weight: 500 !important;
                    //                     line-height: 20px;
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                }

                
            }
        }

        .hamburger {
            position: absolute;
            top: 24px;
            padding: 10px 35px 16px 0px;
            cursor: pointer;
            //z-index: 9999;

            @include media-breakpoint-up(xl) {
                display: none;
            }

            span,
            span:before,
            span:after {
                cursor: pointer;
                border-radius: 1px;
                height: 2px;
                width: 24px;
                background: $white;
                position: absolute;
                display: inline-block;
                content: '';

                -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
                -moz-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
                -o-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
                transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
            }

            span:before {
                top: -10px;
            }

            span:after {
                bottom: -10px;
            }

            &.active {
                span {
                    background-color: transparent;

                    &::after,
                    &::before {
                        top: 0;
                    }
                }
            }
        }

        .hamburger.active span:before {
            transform: rotate(45deg);
        }

        .hamburger.active span:after {
            transform: translateY(-10px) rotate(-45deg);
            top: 10px;
        }
    }
}