.footer {
  background-color: #1A2A6C;
  color: white;
  padding: 40px 0;
  border-top-right-radius: 100px;
  overflow: hidden;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-links {
  /* margin-top: 20px; */
  
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  transition: color 0.3s;
}
/* .footer-links a:hover {
  color: #FFD700;
} */

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.footer-copy {
  padding: 14px 0 0 55px;
  font-size: 13px;
}

.social-icons {
  display: flex;
  gap: 15px;
  /* justify-content: flex-end; */
}

.si-circle {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  transition: background-color 0.3s;
}

.si-circle a {
  color: white;
  font-size: 20px;
}

.si-circle:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
