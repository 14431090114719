body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; /* Prevent scrolling */
}

#root {
  height: 100%; /* Ensures the React app takes full height */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.logo-area{
  display: inline-flex;
  padding: 1%;
  margin-left: 15%;
}
.logo-area h2{
  margin-left: 2%;
  color: #000000;
  margin-top: 1%;
}
.iframe-area{
  width: 100%;
  height: 80vh;
}

.accordion-button {
  background-color: transparent !important;
 }